import React, { useEffect, useMemo, useState } from "react";

import { Alert, Box, Button, CircularProgress, Typography } from "@mui/material";
import { useStripe } from "@stripe/react-stripe-js";
import { PaymentIntent } from "@stripe/stripe-js";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import HomeIcon from '@mui/icons-material/Home';

import { useParams } from "react-router-dom";
import { SHIPPING_FLAT_DOLLARS } from "../utils/constants";

const Confirmation = () => {
    const stripe = useStripe();
    const { secret: clientSecret } = useParams();
    const [payment, setPayment] = useState<PaymentIntent>();
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    const createdDate = useMemo(() => {
        if (!payment?.created) return;

        const d = new Date(0);

        d.setUTCSeconds(payment.created);

        return d;
    }, [payment?.created])

    useEffect(() => {
        localStorage.removeItem("cart");
    }, [])

    useEffect(() => {
        if (stripe && clientSecret) {
            stripe.retrievePaymentIntent(clientSecret)
                .then(({ paymentIntent }) => {
                    if (paymentIntent) {
                        setPayment(paymentIntent);
                        setLoading(false);
                    }
                })
                .catch(err => {
                    setError("There was an error retrieving your payment details. Please contact support.");
                    setLoading(false);
                });
        }
    }, [stripe, clientSecret]);

    if (loading) return <CircularProgress />;
    if (error) return <Alert severity="error">{error}</Alert>;

    return (
        <Box p={4}>
            <Button variant="text" href="/products"><ChevronLeftIcon />
                <HomeIcon />
            </Button>
            <Typography textAlign="center" variant="h2" mt={2} sx={{ fontSize: { xs: "32px", sm: "60px" } }}>Thank you for your purchase!</Typography>
            <Box display="flex" width="100%" justifyContent="center" my={4} sx={{ height: { xs: "160px", sm: "30vh" } }}>
                <img src="/assets/dreamlogo.png" alt="inclusiviteee.com" height="100%" />
            </Box>
            <Typography textAlign="center" variant="h4" fontSize={24}>Your Inclusiviteee.com order is confirmed.</Typography>
            <Box sx={{ mt: 2, px: { xs: 0, sm: 3 } }}>
                <Typography variant="h6">Order Details:</Typography>
                <Typography><strong>Order number:</strong> {payment?.id.substring(payment.id.length - 8)}</Typography>
                {payment?.amount ? <Typography><strong>Amount:</strong> ${(payment.amount / 100 + SHIPPING_FLAT_DOLLARS).toFixed(2)}</Typography> : null}
                <Typography><strong>Status:</strong> Payment {payment?.status}</Typography>
            </Box>
            <Box sx={{ mt: {xs: 2, sm: 4}, px: { xs: 0, sm: 3 } }}>
                <Typography variant="h6">Delivery Details:</Typography>
                <Typography><strong>Order created on:</strong> {`${payment ? createdDate : ""}`}</Typography>
                <Typography><strong>Shipped on:</strong> Pending</Typography>
                <Typography><strong>Expected delivery date:</strong> Pending shipment</Typography>
            </Box>
        </Box>
    )
};

export default Confirmation;