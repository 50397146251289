import React, { useEffect, useState } from 'react';

import { Box, Button, CircularProgress, FormControl, IconButton, InputLabel, MenuItem, Modal, Select, Skeleton, TextField, Typography, styled } from '@mui/material';
import Checkout from '../components/Checkout';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import HomeIcon from '@mui/icons-material/Home';
import Markdown from 'react-markdown';
import { ShoppingCart } from '@mui/icons-material';
import { Cart, CategoryType, Item } from '../types';
import { fetchProduct } from '../utils/api';

const Panel = styled(Box)(({ theme }) => ({
    boxSizing: "border-box",
    display: 'inline-flex',
    height: '100%',
    verticalAlign: "top",
    width: '50%',
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
}));

const LeftPanel = styled(Panel)(({ theme }) => ({
    alignItems: "flex-start",
    backgroundColor: theme.palette.background.paper,
    flexDirection: "column",
    gap: theme.spacing(2),
}));

const RightPanel = styled(Panel)(({ theme }) => ({
    alignItems: "center",
    justifyContent: "center",
}));

const ProductView = () => {
  const navigate = useNavigate();
  const { slug } = useParams();
  const [searchParams] = useSearchParams();

  const clientSecret = searchParams.get("payment_intent_client_secret");

  const localCart = localStorage.getItem("cart")
  
  const [activeStep, setActiveStep] = useState(0);
  const [error, setError] = useState<Error>();
  const [selectedVariation, setSelectedVariation] = useState<Item>();
  const [quantity, setQuantity] = useState(1);
  const [cart, setCart] = useState<Cart>(localCart ? JSON.parse(localCart) : []);
  const [isImageLoaded, setImageLoaded] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isCartOpen, setIsCartOpen] = useState(false);


  const { data: product } = useQuery(["fetchProduct", slug], () => fetchProduct(slug || ""), {
    enabled: Boolean(slug),
    onSuccess: (res) => {
      if (!selectedVariation) setSelectedVariation(res.variations.find(variation => variation.inventory));
    }
  });
  const variations = product?.variations;

  const getPrice = (category: CategoryType) => {
    return category === "Youth" ? product?.priceYouth : product?.priceAdult;
  }

  const handleNextImage = () => {
    if (!product?.images) return;

    setCurrentImageIndex((prevIndex) =>
      prevIndex + 1 < product?.images.length ? prevIndex + 1 : prevIndex
    );
  };

  const handlePrevImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : 0));
  };

  const updateCart = (newCart: Cart) => {
    setCart(newCart);
    localStorage.setItem("cart", JSON.stringify(newCart));
  }

  const addToCart = () => {
    if (!product || !selectedVariation) return;

    const itemId = `${slug}_${selectedVariation.category}_${selectedVariation.size}`
    const existingSizeIndex = cart.findIndex(item => item.id === itemId);
    const tempCart = JSON.parse(JSON.stringify([...cart]));

    if (existingSizeIndex >= 0) {
      tempCart[existingSizeIndex].quantity += quantity;
    } else {
      tempCart.push({
        quantity,
        id: itemId,
      })
    }

    updateCart(tempCart);

    setSelectedVariation(product.variations.find(variation => variation.inventory))
    setQuantity(1);
    setIsCartOpen(true);
  };

  const isSoldOut = product && !product.variations.reduce((a, b) => a + (b.inventory || b.quantity || 0), 0);

  const buttonAddToCart = <Button onClick={addToCart} disabled={!selectedVariation?.inventory  || !quantity} variant={'contained'} sx={{ height: "100%", flex: 1, maxWidth: isSoldOut ? 200 : "unset" }}>Add to cart</Button>

  useEffect(() => {
      if (clientSecret) navigate(`/confirmation/${clientSecret}`);
  }, [clientSecret]);

  if (error) {
    return <span>Error: {error.message}</span>;
  }

  return (
    <Box width="100vw" height="100vh" display="flex" flexDirection="column">
      <Box>
        <Box height="100px" display="flex" alignItems="center" width="420px" gap={4} mx="auto" justifyContent="space-between">
          <Button variant="text" href="/products" sx={{ display: "flex", justifyContent: "flex-start", width: "108px" }}>
            <ChevronLeftIcon sx={{ marginLeft: "-8px" }} />
            <HomeIcon />
          </Button>
          <Box>
            <img src="/assets/dreamlogo.png" alt="inclusiviteee" width="64px" />
          </Box>
          <Box width="108px" display="flex" justifyContent="flex-end">
            <Button onClick={() => setIsCartOpen(true)} sx={{ display: cart.length ? "flex" : "none" }}>
              <ShoppingCart />
              {`(${cart.reduce((a, b) => a + b.quantity, 0)})`}
            </Button>
          </Box>
        </Box>
      </Box>
      <Box display="flex" sx={{ flexDirection: { xs: "column", sm: "row" }, height: { xs: "fit-content", sm: "calc(100% - 100px)" } }}>
        <LeftPanel height="100%" px={4} sx={{ overflow: { xs: "auto", sm: "hidden" } }}>
          <Box width="100%" display="flex" flexDirection="column" justifyContent="space-between" flexGrow={1} sx={{ height: { xs: "fit-content", sm: "calc(100% - 40px)" }, transition: "600ms all" }}>
            <Box height="calc(100% - 178px)">
              <Box>
                {product ? <Typography fontFamily='Bodoni Moda SC, sans-serif' variant='h2' fontWeight="bold" height="130px" overflow="hidden" sx={{ fontSize: { xs: "32px", sm: "36px" } }}>{product.name}</Typography> : <Skeleton variant='text' width={350} height={80} />}
                {product ? <Typography>{`$${product.priceYouth} - $${product.priceAdult}`}</Typography> : <Skeleton variant='text' width={100} height={24} />}
              </Box>
              <Box my={4} width="100%" gap={1} alignItems="center" sx={{ display: { xs: "flex", sm: "none" }, flexDirection: { xs: "column", sm: "row" } }}>
                <Box display="flex" gap={1} justifyContent="center">
                  {!isSoldOut ? (
                    <>
                      <FormControl sx={{ width: "120px" }}>
                      <InputLabel id="size-select-label">Size</InputLabel>
                        <Select
                            labelId="size-select-label"
                            id="size-select"
                            value={`${slug}_${selectedVariation?.category}_${selectedVariation?.size}`}
                            label="Size"
                            onChange={(e) => {
                              const [, category, size] = e.target.value.split('_');
                              const variation = product?.variations.find(v => v.category === category && v.size === size)

                              setSelectedVariation(variation);
                            }}
                            renderValue={() => {
                              return <Typography>{`${selectedVariation?.category} ${selectedVariation?.size}`}</Typography>
                            }}
                        >
                            {variations?.map((variation: Item) => (
                              <MenuItem disabled={!variation.inventory} key={`${variation?.category}_${variation?.size}`} value={`${slug}_${variation?.category}_${variation?.size}`} sx={{ color: ({ palette }) => variation.inventory ? palette.text.primary : palette.text.disabled}}>
                                <Box mr={1}>{`${variation.category} ${variation.size} $${getPrice(variation.category)}`}</Box>
                                {variation.inventory === 1 && <Box ml="auto">{`${variation.inventory} left!`}</Box>}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                      <TextField InputProps={{ inputProps: { min: 1, max: selectedVariation?.inventory } }} label="Quantity" type='number' value={quantity} onChange={e => setQuantity(+e.target.value)} sx={{ width: "80px" }} />
                    </>
                  ) : <Typography>Sold out</Typography>}
                </Box>
                {buttonAddToCart}
              </Box>
              <Box flexDirection="column" sx={{ display: { xs: "flex", sm: "none" } }}>
                {product ? (
                <>
                  <img
                    height="280px"
                    width="100%"
                    src={product.images[currentImageIndex]}
                    alt={`${product.name} - ${currentImageIndex + 1}`}
                    onLoad={() => setImageLoaded(true)}
                    style={{ opacity: isImageLoaded ? 1 : 0, transition: "opacity 0.5s", objectFit: "cover", overflow: "hidden" }}
                  />

                  <Box display="flex" justifyContent="center">
                    <IconButton onClick={handlePrevImage} disabled={currentImageIndex === 0}>
                      <ChevronLeftIcon sx={{ color: currentImageIndex === 0 ? "#999" : "black" }} />
                    </IconButton>
                    <IconButton onClick={handleNextImage} disabled={currentImageIndex === product.images.length - 1}>
                      <ChevronRightIcon sx={{ color: currentImageIndex === product.images.length - 1 ? "#999" : "black" }} />
                    </IconButton>
                  </Box>
                </>
              ) : (
                <CircularProgress />
              )}
              </Box>
              <Box display="flex" flexDirection="column" overflow="auto" sx={{ gap: {xs: 0, sm: 2}, maxHeight: { xs: "100%", sm: "calc(100% - 108px)" }, boxShadow: "inset 0 -10px 10px -15px rgba(0,0,0,0.75)" }}>
                <Markdown>{product?.description}</Markdown>
              </Box>
            </Box>
            <Box my={8} width="100%" gap={1} alignItems="center" sx={{ display: { xs: "none", sm: "flex" }, flexDirection: { xs: "column", sm: "row" } }}>
              <Box display="flex" gap={1} justifyContent="center">
                {!isSoldOut ? (
                  <>
                    <FormControl sx={{ width: "120px" }}>
                    <InputLabel id="size-select-label">Size</InputLabel>
                      <Select
                          labelId="size-select-label"
                          id="size-select"
                          value={`${slug}_${selectedVariation?.category}_${selectedVariation?.size}`}
                          label="Size"
                          onChange={(e) => {
                            const [, category, size] = e.target.value.split('_');
                            const variation = product?.variations.find(v => v.category === category && v.size === size)

                            setSelectedVariation(variation);
                          }}
                          renderValue={() => {
                            return <Typography>{`${selectedVariation?.category} ${selectedVariation?.size}`}</Typography>
                          }}
                      >
                          {variations?.map((variation: Item) => (
                            <MenuItem key={`${variation?.category}_${variation?.size}`} value={`${slug}_${variation?.category}_${variation?.size}`} sx={{ color: ({ palette }) => variation.inventory ? palette.text.primary : palette.text.disabled}}>
                              <Box mr={1}>{`${variation.category} ${variation.size} $${getPrice(variation.category)}`}</Box>
                              {variation.inventory === 1 && <Box ml="auto">{`${variation.inventory} left!`}</Box>}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                    <TextField InputProps={{ inputProps: { min: 1, max: selectedVariation?.inventory } }} label="Quantity" type='number' value={quantity} onChange={e => setQuantity(+e.target.value)} sx={{ width: "80px" }} />
                  </>
                ) : <Typography>Sold out</Typography>}
              </Box>
              {buttonAddToCart}
            </Box>
          </Box>
        </LeftPanel>
        <RightPanel flexDirection="column" height="100%" px={4} overflow="hidden" sx={{ display: { xs: "none", sm: "flex" } }}>
          {product ? (
            <>
              <img
                height="calc(100% - 40px)"
                width="100%"
                src={product.images[currentImageIndex]}
                alt={`${product.name} - ${currentImageIndex + 1}`}
                onLoad={() => setImageLoaded(true)}
                style={{ opacity: isImageLoaded ? 1 : 0, transition: "opacity 0.5s", objectFit: "cover", overflow: "hidden" }}
              />

              <Box height="40px" width="100%" display="flex" alignItems="center" justifyContent="center">
                <IconButton onClick={handlePrevImage} disabled={currentImageIndex === 0}>
                  <ChevronLeftIcon sx={{ color: currentImageIndex === 0 ? "#999" :"black" }} />
                </IconButton>
                <IconButton onClick={handleNextImage} disabled={currentImageIndex === product.images.length - 1}>
                  <ChevronRightIcon sx={{ color: currentImageIndex === product.images.length - 1 ? "#999" : "black" }} />
                </IconButton>
              </Box>
            </>
          ) : (
            <CircularProgress />
          )}
        </RightPanel>
      </Box>
      <Modal open={isCartOpen} onClose={() => setIsCartOpen(false)} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
        <div>
          <Checkout activeStep={activeStep} cart={cart} setActiveStep={setActiveStep} setCart={updateCart} setIsCartOpen={setIsCartOpen} />
        </div>
      </Modal>
    </Box>
  );
};

export default ProductView;
