import { AddressElement, PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { StripePaymentElementOptions } from "@stripe/stripe-js";
import React, { useMemo, useState } from "react";
import { LoadingButton } from "@mui/lab";
import { useNavigate, useParams } from "react-router-dom";
import { InputLabel, TextField } from "@mui/material";
import { useMutation } from "react-query";
import { Cart } from "../types";

const updateEmail = (paymentIntentId: string, emailToUpdate: string) => fetch(`${process.env.REACT_APP_BASE_URL}/order`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      // Add your authentication headers here if needed
    },
    body: JSON.stringify({
      paymentIntentId: paymentIntentId,
      email: emailToUpdate
    }),
  })
  .then(response => {
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return response.json();
  })
  .catch(error => {
    console.error("There was an error updating the order:", error);
  });

interface PaymentProps { 
    clientSecret: string;
    setCart: (newCart: Cart) => void;
}

const Payment = ({ clientSecret, setCart }: PaymentProps) => {
    const navigate = useNavigate();
    const stripe = useStripe();
    const elements = useElements();

    const [message, setMessage] = useState<string>();
    const [isLoading, setIsLoading] = useState(false);
    const [email, setEmail] = useState("");

    const paymentElementOptions: StripePaymentElementOptions = {
        layout: "tabs"
    };

    const pi = useMemo(() => {
        return clientSecret.split("_").slice(0,2).join("_");
    }, [clientSecret])

    const { mutate } = useMutation(() => updateEmail(pi, email));

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (!stripe || !elements) return;

        setIsLoading(true);

        const paymentElement = elements.getElement(PaymentElement);

        if (!paymentElement) {
            setMessage("Payment Element not properly mounted");
            setIsLoading(false);
            return;
        }

        await mutate();

        const { error } = await stripe.confirmPayment({
            elements,
            confirmParams: { return_url: window.location.href, payment_method_data: {
                billing_details: {
                    email
                },
            }}
        });

        if (error) {
            setMessage(error.message || "An unexpected error occurred.");
        } else {
            setMessage("Payment succeeded!");
            setCart([]);
            navigate(`/confirmation/${clientSecret}`);
        }

        setIsLoading(false);
    };

    return (
        <form id="payment-form" style={{ overflowY: "auto", maxHeight: "calc(100% - 80px)" }}>
            <InputLabel shrink htmlFor="email-input">
                Email
            </InputLabel>
            <TextField placeholder="Email address" autoFocus id="email-input" fullWidth value={email} onChange={(e) => setEmail(e.target.value)} InputProps={{ sx: { height: "44px", mb: 1 } }} />
            <AddressElement id="address-element" options={{ mode: "shipping" }} />
            <PaymentElement id="payment-element" options={paymentElementOptions} />
            <LoadingButton variant="contained" onClick={handleSubmit} loading={isLoading} disabled={!elements} sx={{ mt: 2 }}>Submit Payment</LoadingButton>
            {/* Show any error or success messages */}
            {message && <div id="payment-message">{message}</div>}
        </form>
    )
};

export default Payment;