import React, { Dispatch, SetStateAction, useMemo } from "react";

import { Stepper, Typography, Step, StepLabel, Box, Button, Divider, Card, IconButton, CircularProgress } from "@mui/material";
import Payment from "./Payment";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { useQuery } from "react-query";

import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import DeleteIcon from '@mui/icons-material/Delete';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Cart, Item } from "../types";
import { useTotal } from "../hooks/useTotal";
import { fetchPI, fetchProduct } from "../utils/api";
import { useFetchProducts } from "../hooks/useFetchProducts";
import { SHIPPING_FLAT_DOLLARS } from "../utils/constants";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUB_KEY || "");

interface CartProps {
    activeStep: number;
    cart: { id: string, quantity: number }[];
    setCart: (newCart: Cart) => void;
    setActiveStep: Dispatch<SetStateAction<number>>;
    setIsCartOpen: Dispatch<SetStateAction<boolean>>;
};

const steps = ['Finish selecting items', 'Place an order', 'Order confirmation'];

const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

const Checkout = ({ activeStep, cart, setActiveStep, setCart, setIsCartOpen }: CartProps) => {
    const uniqueSlugs = useMemo(() => {
        return [...new Set(cart.map(item => item.id.split('_')[0]))];
    }, [cart]);
    
    const { products, isLoading, error } = useFetchProducts(uniqueSlugs);

    const subtotal = useMemo(() => {
        if (!products) return;

        return cart.reduce((acc, item) => {
            const [slug, category, size] = item.id.split('_');
            const product = products.find(p => p.slug === slug);
            const price = category === "Youth" ? parseFloat(product?.priceYouth || '0') : parseFloat(product?.priceAdult || '0');
            
            return acc + price * item.quantity;
        }, 0);
    }, [cart, products]);

    const { data: clientSecret, isLoading: isLoadingClientSecret } = useQuery(["fetchPI", JSON.stringify(cart)], () => fetchPI(cart), {
        enabled: Boolean(activeStep),
        refetchOnMount: false,
        refetchOnWindowFocus: false,
    });

    const handleIncrement = (itemId: string) => {
        const updatedCart = cart.map(item => 
            item.id === itemId ? {...item, quantity: item.quantity + 1} : item
        );
        setCart(updatedCart);
    };

    const handleDecrement = (itemId: string) => {
        const updatedCart = cart.map(item => 
            item.id === itemId && item.quantity > 1 ? {...item, quantity: item.quantity - 1} : item
        );
        setCart(updatedCart);
    };

    const handleRemove = (itemId: string) => {
        const updatedCart = cart.filter(item => item.id !== itemId);
        setCart(updatedCart);
        if (!updatedCart.length) setIsCartOpen(false);
    };

    return (
        <Card
            sx={{
                boxSizing: "border-box",
                opacity: cart.length ? 1 : 0,
                maxHeight: "100vh",
                overflow: "auto",
                padding: cart.length ? 4 : 0,
                transition: "600ms all",
                width: { xs: "100%", sm: "80vw" }
            }}
        >
            <Stepper activeStep={activeStep} sx={{ mb: 2 }}>
            {steps.map((label) => {
                const stepProps: { completed?: boolean } = {};
                const labelProps: {
                } = {};

                return (
                <Step key={label} {...stepProps}>
                    <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
                );
            })}
            </Stepper>
            {activeStep === 0 && (
                <>
                    <Box mt={2} mb={2} ml={2}>
                        <Typography variant="button">Cart</Typography>
                        {cart.map(item => {
                            const [slug, category, size] = item.id.split('_');
                            const product = products.find(p => p.slug === slug);

                            if (!product) return "Error";

                            const productVariation = product.variations.find(v => v.size === size && v.category === category);
                            const productPrice = category === "Youth" ? product.priceYouth : product.priceAdult;

                            return (
                                <Box key={`cart-${item.id}`} width="100%" display="flex" justifyContent="space-between"
                                    sx={{ flexDirection: { xs: "column", sm: "row" } }}
                                >
                                    <Box display="flex">
                                        <Box sx={{ height: { xs: "100%", sm: "auto" }, width: { xs: 180, sm: 20 } }}>
                                            <img width="100%" height="100%" alt={product.name} src={product.images[0]} />
                                        </Box>
                                        <Typography ml={2}>{`${product.name} ${category} ${size}`}</Typography>
                                    </Box>
                                    <Box display="flex" alignItems="center" gap={2}>
                                        <Box>
                                            <IconButton size="small" disabled={item.quantity <= 1} onClick={() => handleDecrement(item.id)}>
                                                <RemoveIcon fontSize="small" />
                                            </IconButton>
                                            <IconButton size="small" disabled={item.quantity >= (productVariation?.inventory || 0)} onClick={() => handleIncrement(item.id)}>
                                                <AddIcon fontSize="small" />
                                            </IconButton>
                                        </Box>
                                        <Typography>{`${item.quantity} x $${productPrice}`}</Typography>
                                        <IconButton size="small" onClick={() => handleRemove(item.id)}>
                                            <DeleteIcon fontSize="small" />
                                        </IconButton>
                                    </Box>
                                </Box>
                            )
                        })}
                    </Box>
                    <Divider sx={{ my: 1 }} />
                    <Box display="flex" width="100%" flexDirection="column">
                        <Typography variant="body2" ml="auto" textAlign="center">
                            Subtotal: {subtotal ? formatter.format(subtotal) : "Error"}
                        </Typography>
                        <Typography variant="body2" my={1} ml="auto" textAlign="center" color="textSecondary">
                            {`+ $${SHIPPING_FLAT_DOLLARS} Shipping`}
                        </Typography>
                        <Typography variant="h6" mb={2} ml="auto" textAlign="center">
                            Total: {subtotal ? formatter.format(subtotal + SHIPPING_FLAT_DOLLARS) : "Error"}
                        </Typography>
                        <Button onClick={() => setActiveStep(1)} variant="contained" sx={{ mx: "auto" }}>Proceed to Checkout</Button>
                    </Box>
                </>
            )}
            {activeStep === 1 && (
                <>
                {clientSecret ? (
                    <Elements stripe={stripePromise} options={{ clientSecret }}>
                        <Box ml={-4} my={2}><Button onClick={() => setActiveStep(0)}><ChevronLeftIcon /> Back to cart</Button></Box>
                        <Payment clientSecret={clientSecret} setCart={setCart} />
                    </Elements>
                ) : (
                    <Box width="100%" height="100%" display="flex" justifyContent="center" alignItems="center" >
                        <CircularProgress />
                    </Box>
                )}
                </>
                
            )}
        </Card>
    )
};

export default Checkout;