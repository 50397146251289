import React from "react";

import { Box, Card, CardActionArea, CardActions, CardContent, CardMedia, Typography, Grid, Button } from "@mui/material";
import { useQuery } from "react-query";
import LazyImage from "../components/LazyImage";
import { fetchProducts } from "../endpoints";
import { Product } from "../types";

const Products = () => {
    const { data } = useQuery(["fetchProducts"], fetchProducts);

    return (
        <Box sx={{ flexGrow: 1, padding: 2 }}>
            <Grid container spacing={2} justifyContent="center" alignItems="stretch">
                {data?.map((product: Product, index: number) => (
                    <Grid item key={index} xs={12} sm={6} md={4} lg={3}>
                        <Card sx={{ maxWidth: 600, height: '100%', display: 'flex', flexDirection: 'column' }}>
                            <CardActionArea href={`/products/${product.slug}`} sx={{ flexGrow: 1 }}>
                                <CardMedia
                                    sx={{
                                        height: "30vh",
                                        overflow: "hidden",
                                        position: 'relative'
                                    }}
                                >
                                    <LazyImage
                                        src={product.images[0]}
                                        alt={product.name}
                                        height="100%"
                                    />
                                </CardMedia>
                            </CardActionArea>
                            <CardActions>
                                <Button variant="contained" size="small" color="primary" href={`/products/${product.slug}`}>
                                    Shop
                                </Button>
                            </CardActions>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
}

export default Products;
