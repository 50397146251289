import React from 'react';

import { Container, Typography, Box, Button, styled } from '@mui/material';

const HeroSection = styled(Box)(({ theme }) => ({
  backgroundImage: `url('/assets/dreamlogo.png')`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  height: '100vh',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%', // Full width on mobile
  [theme.breakpoints.up('md')]: { // Larger width on screens larger than 'md' breakpoint
    width: 'calc(100vw - 600px)',
  },
}));

const Title = styled(Typography)({
  fontFamily: 'Raleway, sans-serif',
  fontSize: "24px",
  fontWeight: 700,
  textAlign: 'left',
});

const Subtitle = styled(Typography)(({ theme }) => ({
  fontFamily: 'Raleway, sans-serif',
  fontWeight: 300,
  fontSize: '1.25rem',
  textAlign: 'left',
  maxWidth: '600px',
  marginTop: theme.spacing(2),
}));

const ShopButton = styled(Button)({
  fontFamily: 'Raleway, sans-serif',
  fontWeight: 500,
});

const Landing = () => {
  const isShopOpen = process.env.REACT_APP_OPEN_STORE;

  return (
    <Container maxWidth="xl" disableGutters>
    <Box display="flex">
        <Box width="600px" padding={4}>
            <Box mb={4} width="100%" display="flex" alignItems="center" flexDirection="column">
                <img height="50px" src="/assets/dreamlogo.png" alt="minimalist symbol representing unity and inclusivity of world religions" />
                <Typography variant='h2'>Inclusiviteee</Typography>
            </Box>
            <Title variant='h3'>
                Unity of World Religions
            </Title>
            <Subtitle variant="h6">
            Explore our collection of t-shirts that celebrate unity and harmony across different faiths.
            </Subtitle>
            <Box mt={3}>
            <ShopButton disabled={isShopOpen !== "true"} variant="contained" href="/products">
                Shop Now
            </ShopButton>
            </Box>
        </Box>
      <HeroSection />
    </Box>
    </Container>
  );
};

export default Landing;
