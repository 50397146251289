import React, { useState } from "react";

import { LoadingButton } from "@mui/lab";
import { Box, Typography, TextField } from "@mui/material";
import { useMutation } from "react-query";
import { Order } from "../types";

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

const createShipment = (paymentIntentId: string, trackingNumber: string, expectedDeliveryDate: string, dateShipped: number) => fetch(`${process.env.REACT_APP_BASE_URL}/admin/create-shipment`, {
    method: "POST",
    headers: {
        "x-api-key": process.env.REACT_APP_API_KEY || "",
        "Content-Type": "application/json",
    },
    body: JSON.stringify({ paymentIntentId, trackingNumber, expectedDeliveryDate, dateShipped }),
    mode: 'cors',
  }).then((res) => res.json());

interface AdminOrderProps {
    order: Order;
    refetchOrders: () => void;
};

const AdminOrder = ({ order, refetchOrders }: AdminOrderProps) => {
    const [trackingNumber, setTrackingNumber] = useState("");
    const [expectedDeliveryDate, setExpectedDeliveryDate] = useState("");

    const now = Date.now();

    const { mutate, isLoading } = useMutation(() => createShipment(order.paymentIntentId, trackingNumber, expectedDeliveryDate, now), {
        onSuccess: refetchOrders,
    });

    const markedDeliveredDate = order.deliveredOn && new Date(order.deliveredOn);

    return (
        <Box display="flex" gap={2} border="1px solid black" borderRadius="16px" width="fit-content" padding={2}>
            <Box>
                <Typography variant='h4' fontSize={20} mt={1} fontWeight={500}>{`${order.deliveredOn ? "Marked delivered on" : "Shipping address"}`}</Typography>
                {markedDeliveredDate ? (
                    <Typography>{`${markedDeliveredDate}`}</Typography>
                ) : (
                    <Box ml={1}>
                        <Typography>{`${order.shipping?.name}`}</Typography>
                        <Typography>{`${order.shipping?.address.line1}`}</Typography>
                        {order.shipping?.address.line2 && <Typography>{`${order.shipping?.address.line2}`}</Typography>}
                        <Typography>{`${order.shipping?.address.city}, ${order.shipping?.address.state} ${order.shipping?.address.postal_code} ${order.shipping?.address.country}`}</Typography>
                    </Box>
                )}
                <Box mt={2}>
                    <Typography>{`Order id: ${order._id}`}</Typography>
                    <Typography>{`Amount paid: ${formatter.format(order.amount / 100)}`}</Typography>
                    <Typography>{`Customer email: ${order.email}`}</Typography>
                </Box>
            </Box>
            <Box>
                <Typography variant='h4' fontSize={20} mt={1} fontWeight={500}>Items ordered</Typography>
                <Box ml={1}>
                    {order.items.map(({ id, quantity }) => {
                        const [slug, category, size] = id.split('_');

                        return (
                            <Typography key={id}>{`${quantity} x "${slug}" | Size: ${category} ${size}`}</Typography>
                        )
                    })}
                </Box>
            </Box>
            {!order.deliveredOn && (
                <Box display="flex" flexDirection="column" mt={2} gap={1}>
                    <TextField label="Tracking number" value={trackingNumber} onChange={(e) => setTrackingNumber(e.target.value)} />
                    <TextField label="Expected delivery date" value={expectedDeliveryDate} onChange={(e) => setExpectedDeliveryDate(e.target.value)} />
                    <LoadingButton variant="contained" loading={isLoading} onClick={() => mutate()} disabled={!trackingNumber.length || !expectedDeliveryDate.length}>Mark shipped</LoadingButton>
                </Box>
            )}
        </Box>
    );
}

export default AdminOrder;