import React from "react";

import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';

import { ThemeProvider, createTheme } from "@mui/material";
import { QueryClient, QueryClientProvider } from "react-query";

import './App.css';
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Product from "./views/Product";
import Landing from "./views/Landing";
import Confirmation from "./views/Confirmation";
import Admin from "./views/Admin";
import Products from "./views/Products";

const apptheme = createTheme({
  palette: {
    mode: 'light',
    background: {
      default: "#222",
      paper: "#FFF",
    },
    primary: {
      main: "#222",
    }
  },
});

const queryClient = new QueryClient();
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUB_KEY!);

const App = () => {
  return (
    <Router>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={apptheme}>
          <Elements stripe={stripePromise}>
          <Routes>
            <Route path="/" element={<Landing />} />
            <Route path="/products/:slug" element={<Product />} />
            <Route path="/products" element={<Products />} />
            <Route path="/confirmation/:secret" element={<Confirmation />} />
            <Route path="/admin" element={<Admin />} />
          </Routes>
          </Elements>
        </ThemeProvider>
      </QueryClientProvider>
    </Router>
  );
}

export default App;
