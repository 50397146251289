import { Cart, Product } from "../types";

export const fetchPI = (items: Cart) => fetch(`${process.env.REACT_APP_BASE_URL}/create-payment-intent`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ items }),
    mode: 'cors',
  })
    .then((res) => res.json())
    .then((data) => data.clientSecret);

export const fetchProduct = (slug: string): Promise<Product> => fetch(`${process.env.REACT_APP_BASE_URL}/product/${slug}`, {
    method: 'GET',
    headers: {
        "Content-Type": "application/json",
    },
    redirect: 'follow'
  })
  .then(response => response.json())
  .catch(error => console.log('error', error));