export const sizeOption = ["XS", "S", "M", "L", "XL", "2XL", "3XL"] as const;
export type SizeType = typeof sizeOption[number];

export const categoryOptions = ["Youth", "Adult"] as const;
export type CategoryType = typeof categoryOptions[number];

export type Item = {
    category: CategoryType;
    inventory: number;
    size: SizeType;
    quantity?: number; //deprecated field
};

export type Product = {
    _id: string;
    name: string;
    slug: string;
    description: string;
    priceYouth: string;
    priceAdult: string;
    images: string[];
    variations: Item[];
};

export type CartItem = { id: string, quantity: number };

export type Cart = CartItem[];

export type Order = {
    amount: number;
    email?: string;
    dateShipped?: string;
    deliveredOn?: string;
    expectedDeliveryDate?: string;
    _id: string;
    items: { id: string, quantity: number }[];
    paymentCreated: number;
    paymentIntentId: string;
    shipping?: { address: { line1: string, city: string, country: string, line2?: string, postal_code: string, state: string }, name: string };
    trackingNumber?: string;
};