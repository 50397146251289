import React, { useState } from "react";

import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Modal, Typography } from "@mui/material";
import { useMutation, useQuery } from "react-query";
import { deleteProduct, fetchProducts } from "../../../endpoints";
import AddProduct from "../components/AddProduct";
import { Delete, Edit } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Product } from "../../../types";

const AdminProducts = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
    const [productIdToDelete, setProductIdToDelete] = useState<string>();
    const [modalMode, setModalMode] = useState<"add" | "edit">('add');
    const [editingProduct, setEditingProduct] = useState<Product>();
    
    const { data: products, isLoading: isLoadingProducts, refetch: refetchProducts } = useQuery(["fetchProducts"], fetchProducts);
    const { mutate: handleDeleteProduct, isLoading: isDeletingProduct } = useMutation(deleteProduct, {
        onSuccess: refetchProducts
    });

    const handleOpenModal = (product?: Product) => {
        if (product) {
            setModalMode('edit');
            setEditingProduct(product);
        } else {
            setModalMode('add');
            setEditingProduct(undefined);
        }
        setIsModalOpen(true);
    };

    const handleCloseModal = () => setIsModalOpen(false);
    const onAddProduct = async () => {
        handleCloseModal();
        await refetchProducts();
    };

    const handleOpenDeleteConfirmation = (productId: string) => {
        setProductIdToDelete(productId);
        setDeleteConfirmationOpen(true);
    };

    const handleCloseDeleteConfirmation = () => {
        setDeleteConfirmationOpen(false);
        setProductIdToDelete(undefined);
    };

    const confirmDeleteProduct = () => {
        if (productIdToDelete) {
            handleDeleteProduct(productIdToDelete);
        }
        handleCloseDeleteConfirmation();
    };

    return (
        <Box>
            <Box display="flex" flexDirection="column" gap={1}>
                <Box display="flex" alignItems="center">
                    <Typography variant="h2">Products</Typography>
                    <Button variant="contained" onClick={() => handleOpenModal()} sx={{ height: "40px", ml: "auto" }}>Add New Product</Button>
                </Box>
                {isLoadingProducts ? <CircularProgress /> : products?.map((product: Product) => {
                    const youthVariations = product.variations.filter(variation => variation.category === "Youth");
                    const adultVariations = product.variations.filter(variation => variation.category === "Adult");

                    return (
                        <Box display="flex" alignItems="center" key={product._id}>
                            {product.images.map((imageSrc, imageIndex) => <img key={imageSrc} height={64} src={imageSrc} alt={`${product.name}-${imageIndex}`} style={{ marginRight: "4px" }} />)}
                            <Box display="flex" flexDirection="column">
                                <Typography mr={1} fontWeight={600}>{product.name}</Typography>
                                <Box display="flex" gap={1} alignItems="center">
                                    <Typography>Youth: ${product.priceYouth} | In stock:</Typography>
                                    {youthVariations.length ? youthVariations.map(variation => (
                                        <Typography key={`${variation.category}-${variation.size}`}>{variation.size}: {variation.inventory}</Typography>
                                    )) : "No Youth sizes in stock."}
                                </Box>
                                <Box display="flex" gap={1} alignItems="center">
                                    <Typography>Adult: ${product.priceAdult} | In stock:</Typography>
                                    {adultVariations.length ? adultVariations.map(variation => (
                                        <Typography key={`${variation.category}-${variation.size}`}>{variation.size}: {variation.inventory}</Typography>
                                    )) : "No Adult sizes in stock."}
                                </Box>
                            </Box>
                            <Box ml="auto" display="flex" gap={1}>
                                <Button variant="outlined" onClick={() => handleOpenModal(product)}>
                                    <Edit fontSize="small" />
                                    <Typography ml={1}>Edit</Typography>
                                </Button>
                                <LoadingButton loading={isDeletingProduct} onClick={() => handleOpenDeleteConfirmation(product._id)}>
                                    <Delete color="primary" />
                                </LoadingButton>
                            </Box>
                        </Box>
                    )
                })}
            </Box>
            <Modal
                open={isModalOpen}
                onClose={handleCloseModal}
                aria-labelledby="add-product-modal-title"
                aria-describedby="add-product-modal-description"
                sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
            >
                <Box borderRadius="10px" p={2} width={1000} maxHeight="80vh" overflow="auto" sx={{ bgcolor: "background.paper" }}>
                    <AddProduct mode={modalMode} product={editingProduct} onSuccess={onAddProduct} />
                </Box>
            </Modal>
            <Dialog
                open={deleteConfirmationOpen}
                onClose={handleCloseDeleteConfirmation}
                aria-labelledby="delete-confirmation-dialog-title"
                aria-describedby="delete-confirmation-dialog-description"
            >
                <DialogTitle id="delete-confirmation-dialog-title">{"Confirm Deletion"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="delete-confirmation-dialog-description">
                        Are you sure you want to delete this product? This action cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDeleteConfirmation}>Cancel</Button>
                    <Button onClick={confirmDeleteProduct} autoFocus>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    )
};

export default AdminProducts;