import React, { useState } from 'react';
import { Box, Link, CircularProgress } from '@mui/material';

interface LazyImageProps {
    src: string;
    alt: string;
    [key: string]: any;
}

const LazyImage = ({ src, alt, ...rest }: LazyImageProps) => {
    const [loaded, setLoaded] = useState(false);
  
    return (
      <Box
        {...rest}
        display="flex"
        justifyContent="center"
        alignItems="center"
        position="relative"
        overflow="hidden"
        sx={{ filter: loaded ? 'none' : 'blur(8px)' }}
      >
        {!loaded && (
          <CircularProgress
            size={24}
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              marginTop: '-12px', // half of size
              marginLeft: '-12px', // half of size
            }}
          />
        )}
        <img
          src={src}
          alt={alt}
          style={{
            width: '100%',
            height: '100%',
            transition: 'opacity 0.5s ease-in-out',
            opacity: loaded ? 1 : 0,
            objectFit: "contain",
            overflow: "hidden",
          }}
          onLoad={() => setLoaded(true)}
        />
      </Box>
    );
  };

  export default LazyImage;