import { useState, useEffect } from 'react';

import { fetchProduct } from "../utils/api"; 
import { Product } from '../types';

export const useFetchProducts = (slugs: string[]) => {
    const [products, setProducts] = useState<Product[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<any>(null);

    useEffect(() => {
        const fetchProducts = async () => {
            setIsLoading(true);
            setError(null);

            try {
                const productDetails = await Promise.all(
                    slugs.map(slug => fetchProduct(slug))
                );
                setProducts(productDetails);
            } catch (err) {
                setError(err);
            } finally {
                setIsLoading(false);
            }
        };

        if (slugs.length > 0) {
            fetchProducts();
        }
    }, [slugs]);

    return { products, isLoading, error };
};
