import { Item } from "./types";

export const fetchProducts = () => fetch(`${process.env.REACT_APP_BASE_URL}/products`, {
    method: 'GET',
    headers: {
        "Content-Type": "application/json",
    },
    redirect: 'follow'
  })
  .then(response => response.json())
  .catch(error => console.log('error', error));

const API_KEY = process.env.REACT_APP_API_KEY

export const createProduct = (newProduct: { name: string, description: string, priceYouth: string, priceAdult: string }, images: string[], variations: Item[]) => fetch(`${process.env.REACT_APP_BASE_URL}/admin/products`, {
    method: 'POST',
    headers: {
        "x-api-key": API_KEY || "",
        "Content-Type": "application/json",
    },
    body: JSON.stringify({ name: newProduct.name, description: newProduct.description, priceYouth: newProduct.priceYouth, priceAdult: newProduct.priceAdult, images, variations }),
    redirect: 'follow'
  })
  .then(response => response.json())
  .catch(error => console.log('error', error));

export const deleteProduct = (id: string) => fetch(`${process.env.REACT_APP_BASE_URL}/admin/products/${id}`, {
    method: 'DELETE',
    headers: {
        "x-api-key": API_KEY || "",
        "Content-Type": "application/json",
    },
    redirect: 'follow'
  })
  .then(response => response.json())
  .catch(error => console.log('error', error));

export const updateProduct = (id: string, updatedProduct: { name: string, description: string, priceYouth: string, priceAdult: string }, images: string[], variations: Item[]) => fetch(`${process.env.REACT_APP_BASE_URL}/admin/products/${id}`, {
    method: 'PATCH',
    headers: {
        "x-api-key": API_KEY || "",
        "Content-Type": "application/json",
    },
    body: JSON.stringify({ name: updatedProduct.name, description: updatedProduct.description, priceYouth: updatedProduct.priceYouth, priceAdult: updatedProduct.priceAdult, images, variations }),
    redirect: 'follow'
})
.then(response => response.json())
.catch(error => console.log('error', error));
