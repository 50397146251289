import React, { useState } from "react";
import { Box, Button, TextField, TextareaAutosize, Typography } from "@mui/material";
import { useMutation } from "react-query";
import { createProduct, updateProduct } from "../../../endpoints";
import { LoadingButton } from "@mui/lab";
import Markdown from "react-markdown";
import { Product, sizeOption, SizeType, CategoryType } from "../../../types";

const initialNewProduct = {
    name: "",
    description: "",
    priceYouth: "",
    priceAdult: "",
};

interface AddProductProps {
    mode: "edit" | "add";
    product?: Product;
    onSuccess: () => void;
}

const initializeVariations = () => {
    const initialVariations: Record<string, Record<string, number>> = { Adult: {}, Youth: {} };
    sizeOption.forEach(size => {
        initialVariations.Adult[size as SizeType] = 0;
        initialVariations.Youth[size as SizeType] = 0;
    });
    return initialVariations;
};

const AddProduct = ({ mode, product, onSuccess }: AddProductProps) => {
    const initialState = (mode === 'edit' && product) || initialNewProduct;

    const [newProduct, setNewProduct] = useState(initialState);
    const [imageUrl, setImageUrl] = useState("");
    const [images, setImages] = useState<string[]>(product?.images || []);
    const [variations, setVariations] = useState<Record<string, Record<SizeType, number>>>(product?.variations.reduce((acc, v) => {
        if (!acc[v.category]) acc[v.category] = {} as Record<SizeType, number>;
        acc[v.category][v.size as SizeType] = v.inventory;
        return acc;
    }, initializeVariations()) || initializeVariations());

    const { mutate: addProduct, isLoading: isAddingProduct } = useMutation(() => createProduct({ ...newProduct }, images, Object.keys(variations).flatMap(category => Object.keys(variations[category]).map(size => ({
        size: size as SizeType,
        inventory: variations[category][size as SizeType],
        category: category as CategoryType
    })))), {
        onSuccess: () => {
            setNewProduct(initialNewProduct);
            setImageUrl("");
            setImages([]);
            setVariations(initializeVariations());
            onSuccess();
        }
    });

    const { mutate: editProduct, isLoading: isEditingProduct } = useMutation(() => updateProduct(product?._id || "", { ...newProduct }, images, Object.keys(variations).flatMap(category => Object.keys(variations[category]).map(size => ({
        size: size as SizeType,
        inventory: variations[category][size as SizeType],
        category: category as CategoryType
    })))), {
        onSuccess: () => {
            setNewProduct(initialNewProduct);
            setImageUrl("");
            setImages([]);
            setVariations(initializeVariations());
            onSuccess();
        }
    });

    const handleChange = (e: any) => {
        const { name, value } = e.target;
        setNewProduct((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleVariationChange = (category: CategoryType, size: SizeType, inventory: number) => {
        setVariations(prevVariations => ({
            ...prevVariations,
            [category]: {
                ...prevVariations[category],
                [size]: inventory,
            },
        }));
    };

    const handleSubmit = (e: any) => {
        e.preventDefault();

        if (mode === "edit") return editProduct();
        else return addProduct();
    };

    return (
        <Box component="form" noValidate sx={{ mt: 1 }}>
            <Typography variant="h6">{`${mode === "add" ? "Add New" : "Edit"} Product`}</Typography>
            <Box>
                {images.map(src => <img src={src} width={100} key={src} />)}
            </Box>
            <Box display="flex" gap={1}>
                <TextField
                    required
                    fullWidth
                    label="Name"
                    name="name"
                    value={newProduct.name}
                    onChange={handleChange}
                    margin="normal"
                />
            </Box>
            <Box height={160} display="flex" gap={1}>
                <Box width="50%" overflow="auto">
                    <Typography>Description</Typography>
                    <TextareaAutosize required name="description" value={newProduct.description} onChange={handleChange} />
                </Box>
                <Box width="50%" overflow="auto">
                    <Typography>Preview</Typography>
                    <Box border="1px solid black" px={1}>
                        <Markdown>{newProduct.description}</Markdown>
                    </Box>
                </Box>
            </Box>
            <Box display="flex" gap={1}>
                <TextField
                    required
                    fullWidth
                    label="Price for Youth"
                    name="priceYouth"
                    value={newProduct.priceYouth}
                    onChange={handleChange}
                    margin="normal"
                    type="number"
                />
                <TextField
                    required
                    fullWidth
                    label="Price for Adult"
                    name="priceAdult"
                    value={newProduct.priceAdult}
                    onChange={handleChange}
                    margin="normal"
                    type="number"
                />
            </Box>
            <Box display="flex" gap={1}>
                <TextField
                    required={!images.length}
                    fullWidth
                    label="Image url"
                    name="imageUrl"
                    value={imageUrl}
                    onChange={(e) => setImageUrl(e.target.value)}
                    margin="normal"
                    type="text"
                />
                <Box width={200} display="flex" alignItems="center" justifyContent="center">
                    <Button variant={images.length ? "outlined" : "contained"} disabled={!imageUrl.length} onClick={() => {
                        setImages(prevImages => [...prevImages, imageUrl]);
                        setImageUrl("");
                    }}>Add image</Button>
                </Box>
            </Box>
            <Box mt={2}>
                <Typography variant="h6">Variations</Typography>
                <Box ml={2}>
                    <Typography variant="body2" mt={2}>Adult</Typography>
                    <Box display="flex" alignItems="center" gap={2} flexWrap="wrap">
                        {sizeOption.map((size) => (
                            <Box key={size} display="inline-block">
                                <TextField
                                    type="number"
                                    label={size}
                                    value={variations.Adult[size as SizeType] || 0}
                                    onChange={(e) => handleVariationChange("Adult", size as SizeType, parseInt(e.target.value) || 0)}
                                    margin="normal"
                                    sx={{ width: "100px" }}
                                />
                            </Box>
                        ))}
                    </Box>
                    <Typography variant="body2" mt={2}>Youth</Typography>
                    <Box display="flex" alignItems="center" gap={2} flexWrap="wrap">
                        {sizeOption.map((size) => (
                            <Box key={size} display="inline-block">
                                <TextField
                                    type="number"
                                    label={size}
                                    value={variations.Youth[size as SizeType] || 0}
                                    onChange={(e) => handleVariationChange("Youth", size as SizeType, parseInt(e.target.value) || 0)}
                                    margin="normal"
                                    sx={{ width: "100px" }}
                                />
                            </Box>
                        ))}
                    </Box>
                </Box>
            </Box>
            <LoadingButton loading={isAddingProduct || isEditingProduct} disabled={!newProduct.description || !newProduct.name || !newProduct.priceAdult || !newProduct.priceYouth || !images.length} onClick={handleSubmit} fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
                {`${mode === "add" ? "Add" : "Update"} Product`}
            </LoadingButton>
        </Box>
    );
}

export default AddProduct;
