import React, { useState } from "react";
import { Box, CircularProgress, Typography, Button } from "@mui/material";
import { DataGrid, GridColDef, GridRenderCellParams, GridSortModel } from '@mui/x-data-grid';
import { useQuery, useMutation } from "react-query";
import { Order } from "../../../types";
import AdminOrder from "../../../components/AdminOrder";
import { LoadingButton } from "@mui/lab";

const fetchOrders = () => fetch(`${process.env.REACT_APP_BASE_URL}/admin/orders`, {
    method: 'GET',
    headers: {
        "x-api-key": process.env.REACT_APP_API_KEY || "",
        "Content-Type": "application/json",
    },
    redirect: 'follow'
})
    .then(response => response.json())
    .catch(error => console.log('error', error));

const markAsDelivered = (paymentIntentId: string) => fetch(`${process.env.REACT_APP_BASE_URL}/admin/orders/deliver`, {
    method: 'POST',
    headers: {
        "x-api-key": process.env.REACT_APP_API_KEY || "",
        "Content-Type": "application/json",
    },
    body: JSON.stringify({ paymentIntentId }),
    redirect: 'follow'
})
    .then(response => response.json())
    .catch(error => console.log('error', error));

const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

const AdminOrders = () => {
    const [selectedForDelivered, setSelectedForDelivered] = useState<string | undefined>();
    const [sortModel, setSortModel] = useState<GridSortModel>([{
        field: "dateShipped",
        sort: "desc"
    }]);

    const { data, isLoading, refetch } = useQuery(["fetchOrders"], fetchOrders, {
        onSuccess: () => setSelectedForDelivered(undefined)
    });
    const { mutate, isLoading: isMarkingDelivered } = useMutation(markAsDelivered, {
        onSuccess: () => {
            refetch();
        },
    });

    const handleMarkAsDelivered = (pId: string) => {
        setSelectedForDelivered(pId)
        mutate(pId);
    };

    const shippedOrdersColumns: GridColDef[] = [
        { field: '_id', headerName: 'Order ID', width: 150 },
        { field: 'email', headerName: 'Customer Email', width: 200 },
        { field: 'amount', headerName: 'Amount Paid', width: 150, valueFormatter: (params) => formatter.format(params.value / 100) },
        { field: 'dateShipped', headerName: 'Shipped On', width: 150, valueFormatter: (params) => new Date(params.value)},
        { field: 'expectedDeliveryDate', headerName: 'Expected Delivery', width: 150 },
        { field: 'trackingNumber', headerName: 'Tracking Number', width: 200 },
        {
            field: 'action',
            headerName: 'Action',
            width: 150,
            sortable: false,
            filterable: false,
            renderCell: (params: GridRenderCellParams) => (
                <LoadingButton loading={selectedForDelivered === params.row.paymentIntentId} variant="contained" color="primary" onClick={() => handleMarkAsDelivered(params.row.paymentIntentId)}>
                    Mark Delivered
                </LoadingButton>
            )
        },
    ];

    return (
        <Box>
            <Box mb={4}>
                <Typography variant='h3'>New orders</Typography>
                <Box ml={2}>
                    {data?.newOrders?.map((order: Order) => (
                        <AdminOrder key={order._id} order={order} refetchOrders={refetch} />
                    ))}
                    {!data?.newOrders?.length && !isLoading && <Typography>No new orders</Typography>}
                    {isLoading && <CircularProgress />}
                </Box>
            </Box>
            <Box mb={4}>
                <Typography mb={2} variant='h3'>Shipped orders</Typography>
                {isLoading && <CircularProgress />}
                {!isLoading && data?.shippedOrders && (
                    <Box height={400}>
                        <DataGrid
                            rows={data.shippedOrders}
                            columns={shippedOrdersColumns}
                            getRowId={(row) => row._id}
                            paginationMode="client"
                            sortModel={sortModel}
                            onSortModelChange={(model) => setSortModel(model)}
                        />
                    </Box>
                )}
                {!data?.shippedOrders?.length && !isLoading && <Typography>No in progress orders</Typography>}
            </Box>
            <Box>
                <Typography variant='h3'>Delivered orders</Typography>
                {data?.deliveredOrders?.map((order: Order) => (
                    <Box key={`delivered-${order._id}`}>
                        <AdminOrder key={order._id} order={order} refetchOrders={refetch} />
                    </Box>
                ))}
                {!data?.deliveredOrders?.length && !isLoading && <Typography>No delivered orders</Typography>}
                {isLoading && <CircularProgress />}
            </Box>
        </Box>
    )
}

export default AdminOrders;
