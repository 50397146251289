import { Box, Button, Tab, Tabs, TextField, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import AdminOrders from './tabs/Orders';
import AdminProducts from './tabs/Products';

const Admin: React.FC = () => {
  const [password, setPassword] = useState('');
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [tabIndex, setTabIndex] = useState(0); // State to keep track of the active tab

  const ADMIN_PASSWORD = process.env.REACT_APP_ADMIN_PASSWORD;

  useEffect(() => {
    const storedPassword = localStorage.getItem('adminPassword');
    if (storedPassword === ADMIN_PASSWORD) {
      setIsAuthenticated(true);
    }
  }, []);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  const handleLogin = () => {
    if (password === ADMIN_PASSWORD) {
      localStorage.setItem('adminPassword', password);
      setIsAuthenticated(true);
    } else {
      alert('Incorrect password!');
    }
  };

  if (!isAuthenticated) {
    return (
      <Box display="flex" alignItems="center" height="100vh" justifyContent="center" flexDirection="column" gap={2}>
        <Typography variant='h2'>Access restricted</Typography>
        <TextField
          type="password"
          placeholder="Enter Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          onKeyDown={(e) => {
            if (e.code === "Enter") handleLogin();
          }}
        />
        <Button variant='contained' disabled={!password.length} onClick={handleLogin}>Login</Button>
      </Box>
    );
  }

  return (
    <Box display="flex" flexDirection="column" p={4} gap={2}>
      <Tabs value={tabIndex} onChange={handleTabChange} aria-label="admin tabs">
        <Tab label="Orders" />
        <Tab label="Products" />
      </Tabs>
      
      {tabIndex === 0 && <AdminOrders />}
      {tabIndex === 1 && <AdminProducts />}
    </Box>
  );
};

export default Admin;
